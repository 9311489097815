import React from "react";
import LoadableComponent from "../helpers/LoadableComponent";

const path = 'pages/errors';

const ErrorRoutes = [
	{
		path: '403',
		element: <LoadableComponent route='Forbidden403' folder={path} />
	},
	{
		path: '404',
		element: <LoadableComponent route='NotFound404' folder={path} />
	},
	{
		path: '*',
		element: <LoadableComponent route='NotFound404' folder={path} />
	}
]

export default ErrorRoutes;