import React, { useState } from 'react'
import { QueryClientProvider, QueryClient } from 'react-query'

const options = {
	defaultOptions: {
		queries: {
			refetchOnReconnect: true,
			refetchOnMount: true,
			retry: 0,
			staleTime: 5 * 60 * 1000,
		},
	},
}

const QueryProvider = ({ children }) => {

	const [queryclient] = useState(() => new QueryClient(options));

	return (
		<QueryClientProvider client={queryclient}>
			{children}
		</QueryClientProvider>
	)

}

export default QueryProvider;