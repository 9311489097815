import { APIMethods } from "../../config/api.config"
import sendAPIRequest from "../../utilities/sendRequest"

const signInWithGoogle = async (token, userType) => {

	try {

		const data = await sendAPIRequest(
			APIMethods.POST,
			'/auth/google',
			{
				token,
				userType
			}
		)

		return {
			error: null,
			data
		}

	} catch (error) {

		return {
			error,
			data: null
		}

	}

}

export default signInWithGoogle;