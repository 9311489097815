const convertToDate = (timePeriod) => {

	const date = new Date();
	const timePeriodType = timePeriod.slice(-1);
	
	timePeriod = Number(timePeriod.slice(0,timePeriod.length-1));

	timePeriod *= 60*60*1000;

	if(timePeriodType === 'd'){
		timePeriod *= 24;
	}

	date.setTime(date.getTime() + timePeriod);

	return date.toString();
}

export const getPayloadFromToken = (token) => {
	return {
		value : token.token,
		expiresIn : convertToDate(token.expiresIn)
	}
}

export const isValidToken = (token) => {
	if(!token) return false;
	const currentDate = new Date();
	const expiryDate = new Date(token.expiresIn)
	return (expiryDate >= currentDate);
}