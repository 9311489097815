import React from "react";
import logo from '../../../assets/site-logo.png';
import { Box, Typography } from "@mui/material";
import './styles.css'

const PageLoader = () => {
	return (
		<Box
			display="flex"
			justifyContent="center"
			alignItems="center"
			flexDirection="column"
			height="100vh"
		>
			<img 
				className='loading-image'
				loading="lazy"
				src={logo} 
				alt="loading" 
			/>
			<br />
			<Typography
				sx={{
					fontWeight: 'bold',
					color: 'textPrimary',
				}}
				variant="h5"
				color="textPrimary"
				gutterBottom align='center'>Loading...</Typography>
		</Box>
	)
}

export default PageLoader;