export const APIMethods = {
	POST : 'POST',
	GET : 'GET',
	PUT : 'PUT',
	DELETE : 'DELETE'
}

export const UserType = {
	CREATOR : 'creator',
	STRATEGIST : 'strategist'
}

export const OrganizationType = {
	PRIMARY: 'primary',
	SECONDARY: 'secondary'
}

export const QueryParamOperation = {
	IN :'in',
	BETWEEN :'between',
	GT :'gt',
	GTE :'gte',
	LT :'lt',
	LTE :'lte',
	ILIKE :'ilike',
	ANY :'any'
}

export const QueryParamType = {
	STRING : 'string',
	NUMBER : 'number',
	DATE : 'date',
	NULL : 'null',
	ENUM : 'enum',
	ANY : 'any'
}

export const ContentPieceStatus = {
	CREATED : 'Created',
	WAITING_FOR_TEMPLATE : 'Waiting for template',
	TEMPLATE_FILLED : 'Template Filled',
	UNASSIGNED : 'Unassigned',
	WAITING_FOR_ACCEPTANCE : 'Waiting for acceptance',
	ACCEPTED : 'Accepted',
	REJECTED : 'Rejected',
	IN_PROGRESS : 'In Progress',
	REMOVE_CREATOR : 'Remove Creator',
	REQUESTING_APPROVAL : 'Requesting Approval',
	AWAITING_REWORK : 'Awaiting Rework',
	APPROVED : 'Approved',
	ON_HOLD : 'On Hold',
	MOVE_TO_EDITING: 'Move to editing',
	COMPLETED : 'Completed',
	INVOICED : 'Invoiced',
	PAID : 'Paid'
}

export const CreatorPolicy = {
	NORMAL : 'normal',
	GHOST : 'ghost'
}

export const CreatorType = {
	PUBLIC : 'public',
	PRIVATE : 'private'
}

export const StrategistRole = {
	PORTAL_ADMIN : 'Portal Admin',
	ORGANIZATION_ADMIN : 'Organization Admin',
	NORMAL : 'Normal'
}

export const ContentPieceType = {
	LONG : 'long',
	SHORT : 'short'
}

export const InvoiceStatus = {
	UNPAID : 'Unpaid',
	PAID : 'Paid'
}

export const CreatorLevels = {
	'Just Starting Out' : [0,5],
	'Almost A Legend' : [5,10],
	'Expert of the Craft' : [10,15],
	'Highly Distinguished': [15,20],
	'Hall of Fame': [20,null]
}

export const DeadlineStatusPartitions = {
	'red' : [0,5],
	'orange' : [5,10],
	'green' : [10,null]
}

export const CreatorRole = {
	CONTENT_WRITER: 'Content Writer',
	CONTENT_EDITOR: 'Content Editor'
}