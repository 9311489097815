import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { getAccessToken } from '../api/login';
import { getFromLocalStorage, saveToLocalStorage } from '../utilities/localStorageUtils';
import { getPayloadFromToken, isValidToken } from '../utilities/tokenUtils';

const AuthStateContext = createContext({});

const initialAuthState = {
	isLoggedIn: false,
	user: null,
	accessToken: null,
	refreshToken: null,
	isStrategist: null,
	invoiceCount: null
}

const authReducer = (state, action) => {
	let newState;
	
	switch (action.type) {
		case 'login':
			newState = Object.assign({}, state, { ...action.payload });
			break;
		case 'logout':
			newState = Object.assign({}, state, initialAuthState);
			break;
		case 'setInvoices':
			newState = { ...state, user: { ...state.user, invoices: action.payload } }
			break;
		default:
			throw new Error();
	}
	
	saveToLocalStorage('writers-portal-auth', newState);
	return newState;
}

const AuthStateProvider = ({ children }) => {

	const [state, dispatch] = useReducer(authReducer, initialAuthState);

	useEffect(() => {

		const loadAuthDetails = async () => {

			const authDetails = getFromLocalStorage('writers-portal-auth');

			if (authDetails && authDetails.isLoggedIn) {
				
				if (isValidToken(authDetails.accessToken)) {
					
					dispatch({
						type: 'login',
						payload: authDetails
					})

				} else if (isValidToken(authDetails.refreshToken)) {

					const { error, data } = await getAccessToken(authDetails.refreshToken.value);

					if (!error) {
						dispatch({
							type: 'login',
							payload: {
								...authDetails,
								accessToken: getPayloadFromToken(data.accessToken)
							}
						})
					}

				}
			}
		}

		loadAuthDetails();
	}, [])

	return (
		<AuthStateContext.Provider value={{ state, dispatch }}>
			{children}
		</AuthStateContext.Provider>
	)

}

export default AuthStateProvider;

export const useAuthState = () => {
	const { state } = useContext(AuthStateContext);
	return state;
}

export const useAuthDispatch = () => {
	const { dispatch } = useContext(AuthStateContext);
	return dispatch;
}