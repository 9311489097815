import React, { lazy, Suspense } from "react";
import PageLoader from "../components/loaders/PageLoader";

const LoadableComponent = ({ route, folder = 'pages', children, ...rest}) => {

	const Component = lazy(() => {
		const module = import(`../${folder}/${route}`);
		return module;
	})

	return (
		<Suspense fallback={ <PageLoader/> }>
        	<Component {...rest}>
				{children}
			</Component>
      	</Suspense>
	)
}	

export default LoadableComponent;