import React from "react";
import { Navigate } from "react-router";
import LoadableComponent from "../helpers/LoadableComponent";

const path = 'pages';

const AuthRoutes = [
	{
		path: '',
		element: <Navigate to='/signin'/>
	},
	{
		path: 'signin',
		element: <LoadableComponent route='signIn' folder={path} />
	}
]

export default AuthRoutes;