import React, { useEffect, useContext } from "react";
import { BrowserRouter, useRoutes } from "react-router-dom";
import Provider from "../context";
import AuthStateProvider from "../context/AuthStateProvider";
import AuthRoutes from "./authRoutes";
import AdminRoutes from "./adminRoutes";
import ContentPiecesRoutes from "./contentPiecesRoutes";
import CreatorsRoutes from "./creatorsRoutes";
import ErrorRoutes from "./errorRoutes";
import ProjectsRoutes from "./projectsRoutes";
import StrategistsRoutes from "./strategistsRoutes";

const Routes = () => {

	const routes = useRoutes([
		...AuthRoutes,
		...CreatorsRoutes,
		...StrategistsRoutes,
		...ProjectsRoutes,
		...ContentPiecesRoutes,
		...ErrorRoutes,
		...AdminRoutes
	])

	return routes;
}

const Router = () => {

	return (

		<AuthStateProvider>
			<BrowserRouter>
				<Provider>
					{/* <ShepherdTour steps={steps} tourOptions={tourOptions} > */}
						<Routes />
					{/* </ShepherdTour> */}
				</Provider>
			</BrowserRouter>
		</AuthStateProvider>

	)
}

export default Router;