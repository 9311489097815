import React from "react";
import LoadableComponent from "../helpers/LoadableComponent";

const path = 'pages';

const ProjectsRoutes = [
	{
		path: 'projects',
		element: (
			<LoadableComponent  route='StrategistPrivateRoute' folder='helpers'>
				<LoadableComponent route='ProjectLayout' folder='layouts' />
			</LoadableComponent>
		),
		children: [
			{
				path: ':projectId',
				element: <LoadableComponent route='projects' folder={path}/>
			}
		]
	},
	
]

export default ProjectsRoutes;