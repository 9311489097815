import React from "react";
import fallbackImage from '../../../assets/fallback.png'
import './styles.css'
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
const PrimaryFallback = () => {
	const navigate = useNavigate();
	return (
		<Box
			display="flex"
			justifyContent="center"
			alignItems="center"
			flexDirection="column"
			height="100vh"
			width="100vw"
		>
			<img className="maintenance-image"
				loading="lazy"
				src={fallbackImage} alt="404" />
			<br />
			<Typography
				sx={{
					fontWeight: 'bold',
					fontSize: '2rem',
					color: 'textPrimary',
				}}
				variant="h4"
				color="textPrimary"
				gutterBottom align='center'>We are under maintenance. Try again later.</Typography><br />
			<Button
				sx={{
					color: 'white',
				}}
				variant="contained"
				color="secondary"
				onClick={
					() => navigate('/signin')
				}>Go Back</Button>
		</Box>
	)
}

export default PrimaryFallback;