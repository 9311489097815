import React from "react";
import LoadableComponent from "../helpers/LoadableComponent";

const path = 'pages/admin';

const AdminRoutes = [
	{
		path: 'admin',
		element: (
			<LoadableComponent  route='AdminPrivateRoute' folder='helpers'>
				<LoadableComponent route='AdminLayout' folder='layouts' />
			</LoadableComponent>
		),
		children: [
			{
				path : 'organizations',
				element: <LoadableComponent route='Organizations' folder={path}/>
			},
			{
				path : 'tests',
				element: <LoadableComponent route='Tests' folder={path}/>
			},
			{
				path : 'templates',
				element: <LoadableComponent route='Templates' folder={path}/>
			}
		]
	},
	
]

export default AdminRoutes;