export const getFromLocalStorage = (key) => {
	let value = localStorage.getItem(key);

	if(!value) return null;
	return JSON.parse(value);
}

export const saveToLocalStorage = (key,value) => {
	let item = JSON.stringify(value);
	localStorage.setItem(key, item);
}