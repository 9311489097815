import React, { createContext, useContext } from "react";
import toast, { Toaster } from 'react-hot-toast'

const ToastContext = createContext({});

const options = {
	position: "top-right"
}

const ToastProvider = ({ children }) => {

	const events = {

		addSuccess: (message) => {
			toast.success(message)
		},

		addError: (message = 'An Error Occured.') => {
			toast.error(message)
		}

	}

	return (
		<ToastContext.Provider value={events}>
			{children}
			<Toaster {...options} />
		</ToastContext.Provider>
	)

}

export default ToastProvider;

export const useToastEvents = () => {
	return useContext(ToastContext)
}