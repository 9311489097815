import { APIMethods } from "../../config/api.config"
import sendAPIRequest from "../../utilities/sendRequest"

const getAccessToken = async (refreshToken) => {

	try {

		const data = await sendAPIRequest(
			APIMethods.POST,
			'/auth/refresh',
			{},
			{},
			{
				'Refresh': `Bearer ${refreshToken}`
			}
		)

		return {
			error: null,
			data
		}

	} catch (error) {

		return {
			error,
			data: null
		}

	}

}

export default getAccessToken;