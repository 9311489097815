import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider as Provider} from '@mui/x-date-pickers/LocalizationProvider';

const LocalizationProvider = ({ children }) => {
	
	return (
    	<Provider dateAdapter={AdapterMoment}>
      		{children}
   	 	</Provider>
  	);
}

export default LocalizationProvider;