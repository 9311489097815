import axios from 'axios';
import qs from 'qs';
import { BASE_API_URL } from '../config';

const sendAPIRequest = async (
	method,
	url,
	data,
	params = {},
	extraHeaders = {},
	includePagination = false
) => {

	const headers = {
		'Accept' : 'application/json',
		'Content-Type' : 'application/json',
		...extraHeaders
	}

	return await axios({
		method,
		url : `${BASE_API_URL}${url}`,
		data,
		params,
		headers,
		paramsSerializer: params => {
			return qs.stringify(params, { encode: false, arrayFormat: 'indices' })
		}
	})
	.then(response => includePagination? response.data : response.data.data)
	.catch(error => {
		const message = error.response.data?.message || "An Error Occured."
		throw new Error(message)
	})
	
}

export default sendAPIRequest;