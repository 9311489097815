import React from "react";
import LoadableComponent from "../helpers/LoadableComponent";

const path = 'pages';

const ContentPiecesRoutes = [
	{
		path: 'content-pieces',
		element: (
			<LoadableComponent route='CommonPrivateRoute' folder='helpers' >
				<LoadableComponent route='ProjectLayout' folder='layouts' />
			</LoadableComponent>
		),
		children: [
			{
				path: ':contentPieceId',
				element: <LoadableComponent route='content-pieces' folder={path}/>
			}
		]
	},
	
]

export default ContentPiecesRoutes;