import React, { createContext, useContext } from 'react';
import { useAuthDispatch, useAuthState } from './AuthStateProvider';
import { ContentPieceType, OrganizationType, UserType } from '../config/api.config'
import { StrategistRole } from '../config/api.config';
import { getPayloadFromToken } from '../utilities/tokenUtils';
import { useNavigate } from 'react-router-dom';

const AuthDispatchContext = createContext({});

const AuthDispatchProvider = ({ children }) => {

	const { user : loggedInUser, isStrategist } = useAuthState()
	const dispatch = useAuthDispatch();
	
	const navigate = useNavigate();

	const events = {

		handleLogin: (response, userType) => {
			const payload = {
				isLoggedIn: true,
				user: response.findUser,
				accessToken: getPayloadFromToken(response.accessToken),
				refreshToken: getPayloadFromToken(response.refreshToken),
				isStrategist: userType === UserType.STRATEGIST
			}
	
			dispatch({
				type: 'login',
				payload
			})
		},

		handleReset: () => {
			dispatch({
				type: 'logout'
			})
		},

		handleRefresh: async (token) => {
			const payload = {
				accessToken: getPayloadFromToken(token)
			}

			dispatch({
				type: 'login',
				payload
			})
		},

		handleUpdateUser: (user) => {
			const payload = {
				user
			}

			dispatch({
				type: 'login',
				payload
			})
		},
		
		handleUpdateOrganization : (organization) => {
			const payload = {
				user : {
					...loggedInUser,
					organization
				}
			}
			
			dispatch({
				type: 'login',
				payload
			})
		},

		handleSuccess: (path) => {
			navigate(path, {
				state: { message: "Successfully logged in." }
			})
		},
		
		handleLogout: () => {
			
			navigate('/signin', {
				state: {
					message: "Logged Out",
					logout: true
				},
				replace: true
			})
			
		},

		handleError: (error) => {
			navigate('/signin', {
				state: { 
					error: (typeof error === 'string' ? error : (error.message || "An error occurred. Could not Login.")) 
				}
			})
		},
		
		isAuthenticated : (id) => (loggedInUser && id == loggedInUser.id),
		
		isAdmin : (strategist = loggedInUser) => (strategist.role == StrategistRole.ORGANIZATION_ADMIN || strategist.role == StrategistRole.PORTAL_ADMIN),
		
		isPortalAdmin : (strategist = loggedInUser) => (strategist.role == StrategistRole.PORTAL_ADMIN),
		
		isSelf : (strategist) => (strategist.id == loggedInUser.id),
		
		isPrimaryOrganization : loggedInUser?.organization?.type == OrganizationType.PRIMARY,
		
		canAccess : (creator) => (
			creator && isStrategist && (
				(!creator.organization && loggedInUser.organization?.type == OrganizationType.PRIMARY) || 
				(creator.organization?.id == loggedInUser.organization?.id) || 
				(loggedInUser.type == StrategistRole.PORTAL_ADMIN)
			)
		),
		
		isPreferred: (creator) => (
			creator?.preferredOrganizations && isStrategist && (
				creator.preferredOrganizations.find(organization => organization.id == loggedInUser.organization.id)
			)
		),
		
		getRates: () => ({
			long: `${loggedInUser.agreedAmount.long} ${loggedInUser.agreedAmount.currency}`,
			short: `${loggedInUser.agreedAmount.short} ${loggedInUser.agreedAmount.currency}`,
			longEditing: `${loggedInUser.agreedAmount.longEditing} ${loggedInUser.agreedAmount.currency}`,
			shortEditing: `${loggedInUser.agreedAmount.shortEditing} ${loggedInUser.agreedAmount.currency}`
		})
	}

	return (
		<AuthDispatchContext.Provider value={events}>
			{children}
		</AuthDispatchContext.Provider>
	)

}

export default AuthDispatchProvider;

export const useAuthEvents = () => {
	return useContext(AuthDispatchContext);
}