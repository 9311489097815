import React from "react";
// import CreatorPrivateRoute from "../helpers/CreatorPrivateRoute";
import LoadableComponent from "../helpers/LoadableComponent";

const path = 'pages/creators';

const CreatorsRoutes = [
	{
		path: 'creators',
		element: (
			<LoadableComponent route='CreatorPrivateRoute' folder='helpers'>
				<LoadableComponent route='CreatorLayout' folder='layouts' />
			</LoadableComponent>
		),
		children: [
			{
				path: 'home',
				element: <LoadableComponent route='Home' folder={path}/>
			},
			{
				path : 'find-work',
				element: <LoadableComponent route='FindWork' folder={path}/>
			},
			{
				path : 'earnings',
				element: <LoadableComponent route='Earning' folder={path}/>
			}
		]
	},
	{
		path : 'creators/:id/profile',
		element: <LoadableComponent route='Profile' folder={path}/>
	}
]

export default CreatorsRoutes;