import React, { Suspense } from 'react';
import './App.css';
import PrimaryFallback from './pages/errors/PrimaryFallback';
import Router from './router';

function App() {
  	return (
		<Suspense fallback={<PrimaryFallback/>}>
			<Router/>
		</Suspense>
  	);
}

export default App;
