import React from "react";
import LoadableComponent from "../helpers/LoadableComponent";

const path = 'pages/strategists';

const StrategistsRoutes = [
	{
		path: 'strategists',
		element: (
			<LoadableComponent  route='StrategistPrivateRoute' folder='helpers'>
				<LoadableComponent route='StrategistLayout' folder='layouts' />
			</LoadableComponent>
		),
		children: [
			{
				path: 'home',
				element: <LoadableComponent route='Home' folder={path}/>
			},
			{
				path : 'organization',
				element: <LoadableComponent route='Organization' folder={path}/>
			},
			{
				path : 'find-creators',
				element: <LoadableComponent route='FindCreators' folder={path}/>
			},
			{
				path : 'projects',
				element: <LoadableComponent route='Projects' folder={path}/>
			},
			{
				path : 'profile',
				element: <LoadableComponent route='Profile' folder={path}/>
			},
			// {
			// 	path : 'tests',
			// 	element: <LoadableComponent route='Tests' folder={path}/>
			// },
			{
				path : 'templates',
				element: <LoadableComponent route='Templates' folder={path}/>
			},
			{
				path : 'invoices',
				element: <LoadableComponent route='Invoices' folder={path}/>
			},
		]
	},
	
]

export default StrategistsRoutes;