import React from 'react'
import { ThemeProvider } from '@mui/system'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GOOGLE_CLIENT_ID } from '../config';
import theme from '../theme'
import AuthDispatchProvider from './AuthDispatchProvider'
import QueryProvider from './QueryProvider'
import ToastProvider from './ToastProvider'
import LocalizationProvider from './LocalizationProvider'

const Provider = ({ children }) => {

	return (
		<GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
			<ThemeProvider theme={theme}>
				<AuthDispatchProvider>
					<QueryProvider>
						<ToastProvider>
							<LocalizationProvider>
								{children}
							</LocalizationProvider>
						</ToastProvider>
					</QueryProvider>
				</AuthDispatchProvider>
			</ThemeProvider>
		</GoogleOAuthProvider>
	)

}

export default Provider;